/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-explicit-any */
import rules from "./rules";

export interface ValidationRulesInterface {
  required?:
    | {
        value: boolean;
        message: string;
      }
    | {
        if?: { name?: string; id?: string };
      }
    | boolean;
  equalTo?: { name?: string; id?: string; label?: string };
  requiredIf?: { name?: string; id?: string };
  max?: number;
  maxLength?: number;
  min?: number;
  minLength?: number;
  phone?: boolean;
  email?: boolean;
}

export const setValidation = (
  opts: ValidationRulesInterface | undefined,
  t: Function
) => {
  if (!opts) {
    return {};
  }

  const validate: any = {};

  Object.keys(opts).forEach((key) => {
    // @ts-ignore
    if (rules[key] && opts[key] !== false) {
      validate[key] = (value: string) => {
        // @ts-ignore
        return rules[key](value, opts, t);
      };
    }
  });

  return { validate };
};
